import React, { useState } from "react";
import { IoAdd } from "react-icons/io5";
import "./Accordion.css";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div className={`icon ${isActive ? "rotate" : ""}`}>
          <IoAdd size={24} />
        </div>
      </div>
      <div className={`accordion-content ${isActive ? "show" : ""}`}>
        {content}
      </div>
    </div>
  );
};

export default Accordion;
