import React from "react";
import { CATEGORIES } from "../../constants/Constants";
import './ImageCards.css';


const ImageCards = () => {

    return (
        <div className="cards">
            {CATEGORIES.map((item, index) => {
                return (
                    <div key={index} className="card">
                        <img src={item.imgUri} alt={item.name} />
                        <div className="content">
                            <h2>{item.name}</h2>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}


export default ImageCards;