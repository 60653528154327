import React from "react";
import { STEP_BY_STEP_GUIDE } from "../../constants/Constants";
import './GuideCards.css';

import Lottie from "lottie-react";
import './GuideCards.css';

const GuideCards = () => {
  return (
    <div className="cards">
      {STEP_BY_STEP_GUIDE.map((item, index) => {
        return (
          <div key={index} className="guide-container">
            <div className="lottie-container">
              <Lottie animationData={item.animationPath} loop={true} />
            </div>
            <h2 className="guide-header primary-color-header">{item.title}</h2>
            <h4 className="guide-sub-header">{item.step}</h4>
          </div>
        )
      })
      }
    </div>
  )
}


export default GuideCards;