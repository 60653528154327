import React, { useState } from "react";
// import { Link } from "react-router-dom";
import logo from "../../assets/logo/rentdeer-black.png";
import { AnimatePresence, motion } from "framer-motion";

import { IoCloseOutline, IoReorderThreeOutline } from "react-icons/io5";
import { onContactEmail, onClickAlphaInvite } from "../../utils";
import "./NavBar.css";

const NavLinks = () => {
  return (
    <>
      <div className="nav-link">About</div>
      <div className="nav-link" onClick={onContactEmail}>
        Contact Us
      </div>
      <div className="nav-link">
        <button className="nav-btn" onClick={onClickAlphaInvite}>
          Join Alpha
        </button>
      </div>
    </>
  );
};

const NavBar = () => {
  const [isNavTabOpen, setIsNavTabOpen] = useState(false);
  const onClickNavTab = () => {
    setIsNavTabOpen(!isNavTabOpen);
  };

  return (
    <>
      <nav>
        <div className="nav-link">
          <img className="nav-logo" src={logo} alt="Logo" />
        </div>
        <div className="nav-bar" onClick={onClickNavTab}>
          {isNavTabOpen ? (
            <IoCloseOutline size={32} className="nav-icon" />
          ) : (
            <IoReorderThreeOutline size={32} className="nav-icon" />
          )}
        </div>
        <div className="nav-menu">
          <NavLinks />
        </div>
        <AnimatePresence>
          {isNavTabOpen && (
            <motion.div
              className="nav-tab-menu"
              initial={{ y: -20, opacity: 0 }}
              animate={{
                y: isNavTabOpen ? 0 : -20,
                opacity: isNavTabOpen ? 1 : 0,
              }}
              exit={{ y: -20, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div>
                <NavLinks />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </>
  );
};

export default NavBar;
