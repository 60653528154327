import React from "react";
import NavBar from "../NavBar/NavBar";
import apple from "../../assets/app/apple-store.svg";
import google from "../../assets/app/google-play.png";
import mobile from "../../assets/app/mobile.png";
import "./Header.css";

const Header = () => {
  return (
    <header className="svg-bg">
      <NavBar />
      <div className="app-header">
        <div className="left-header-container">
          <div className="text-header">
            <h1 className="header-title">
              Find your <br />
              <span className="header-col">perfect</span> space
            </h1>
            <h2 className="header-sub-title">
              Unlock hidden spaces — your unused space, their perfect venue,
              your profit!
            </h2>
            <div className="store-icons-container">
              <img src={apple} alt="Apple App Store" />
              <img src={google} alt="Google Play Store" />
            </div>
          </div>
        </div>
        <div className="right-header-container">
          <img src={mobile} className="mobile-svg" alt="Mobile" />
        </div>
      </div>
    </header>
  );
};

export default Header;
