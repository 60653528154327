export const CATEGORIES = [
  {
    name: "Events",
    imgUri: require("../assets/categories/events.jpg"),
  },
  {
    name: "Pool",
    imgUri: require("../assets/categories/pool.jpg"),
  },
  {
    name: "Yacht",
    imgUri: require("../assets/categories/yacht.jpg"),
  },
  {
    name: "Workspace",
    imgUri: require("../assets/categories/work.jpg"),
  },
  {
    name: "Gaming",
    imgUri: require("../assets/categories/gaming.jpg"),
  },
  {
    name: "Movies",
    imgUri: require("../assets/categories/movies.jpg"),
  },
];

export const CONSTANTS = {
  SUPPORT_EMAIL: "business@rentdeer.sg",
};

export const COLORS = {
  white: "#FFF",
  black: "#000",
  primary: "#FFC000",
  secondary: "#FFE134",
  orange: "#FA9C1B",
  lightbase: "#FFF8DC",
  lightGrey: "#EEE",
  lightYellow: "#FFECB3",
};

export const STEP_BY_STEP_GUIDE = [
  {
    title: "Browse",
    step: "Browse our rentals near you",
    animationPath: require("../assets/animations/home1.json"),
  },
  {
    title: "Reserve",
    step: "Reserve your booking with a tap",
    animationPath: require("../assets/animations/reserve3.json"),
  },
  {
    title: "Pay",
    step: "Pay to confirm booking",
    animationPath: require("../assets/animations/pay2.json"),
  },
  {
    title: "Have Fun",
    step: "Show up and have fun",
    animationPath: require("../assets/animations/fun2.json"),
  },
];
