import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ImageCards from "./components/ImageCards/ImageCards";
import InfiniteLooper from "./components/InfiniteLooper/InfiniteLooper";
import ComingSoonCard from "./components/ComingSoonCard/ComingSoonCard";
import BubbleCards from "./components/BubbleCards/BubbleCards";
import GuideCards from "./components/GuideCards/GuideCards";
import FadeInOnScroll from "./components/FadeInOnScroll";
import FAQ from "./components/FAQ/FAQ";

import { COLORS } from "./constants/Constants";
import { onClickAlphaInvite } from "./utils";
import "./App.css";
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize(
    process.env.REACT_APP_GOOGLE_ANALYTICS_PUBLIC_MEASUREMENT_ID
  );

  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "Home",
  });

  return (
    <div className="App">
      <Header />
      <section className="primary-bg">
        <FadeInOnScroll className="extend container about-container">
          <h1 className="title secondary-color-header">
            Discover the future of sharing
          </h1>
          <h2 className="sub-title about-sub-title">
            Revolutionizing the way we approach renting. We aspire to be your
            one-stop platform for all your renting needs
          </h2>
        </FadeInOnScroll>
      </section>

      <div className="bottom-svg"></div>

      <section style={{ backgroundColor: COLORS.lightGrey }}>
        <FadeInOnScroll
          style={{ marginRight: "2rem", marginLeft: "2rem" }}
          className="extend container bottom-svg-extend"
        >
          <div className="left-header" style={{ marginTop: "10vh" }}>
            <h1 className="title">
              <span style={{ color: COLORS.primary }}>Sharing</span> is the
              future
            </h1>
            <div className="sharing-container">
              <div>
                <div style={{ fontSize: 22, fontWeight: "500" }}>
                  The sharing economy is expected to grow from $14 billion to
                  $335 billion by 2025. Rentdeer allows Singaporeans to
                  capitalize on this growing market by renting out their space
                  on an hourly basis.
                </div>
                <div
                  style={{ fontSize: 22, fontWeight: "500", paddingTop: 20 }}
                >
                  Rentdeer is a space sharing platform that allows you to book
                  any space fuss-free for any occasion. Be it a private movie
                  room or a group party venue.
                </div>
                <div
                  style={{ fontSize: 22, fontWeight: "500", paddingTop: 20 }}
                >
                  Our mission is to be your one-stop solution for all your
                  renting needs!
                </div>
              </div>
              <img
                className="sharing-img"
                src={require("./assets/misc/sharing.jpg")}
                alt={"Space Sharing"}
              />
            </div>
          </div>
        </FadeInOnScroll>
      </section>

      <section>
        <FadeInOnScroll className="extend container">
          <div className="left-header">
            <h4 className="highlight">Spaces</h4>
            <h1 className="title">
              Rent for
              <span style={{ color: COLORS.orange, marginRight: -3 }}>
                {" "}
                any
              </span>{" "}
              occasion
            </h1>
            <h2 className="sub-title">
              From pools to private yachts. Rent a space near you.
            </h2>
          </div>
          <ImageCards />
        </FadeInOnScroll>
      </section>

      <section style={{ backgroundColor: COLORS.lightGrey }}>
        <FadeInOnScroll
          style={{ marginBottom: "25vh" }}
          className="extend container"
        >
          <h1 style={{ color: COLORS.primary }} className="main-title">
            How it works
          </h1>
          <h2 className="sub-title">
            4 simple steps to get started with Rentdeer
          </h2>
          <GuideCards />
        </FadeInOnScroll>
      </section>

      <section className="primary-bg">
        <FadeInOnScroll style={{ marginTop: "10vh" }} className="container">
          <BubbleCards />
        </FadeInOnScroll>
      </section>

      <div className="bottom-svg"></div>

      <section style={{ marginTop: "10vh" }}>
        <FadeInOnScroll className="d-flex container bottom-svg-extend">
          <ComingSoonCard />
        </FadeInOnScroll>
      </section>

      <section
        style={{
          background: "linear-gradient(to top, #ffc000 60%, #ffe134)",
          marginTop: "10vh",
        }}
      >
        <FadeInOnScroll>
          <div
            style={{
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            <div className="section-header">Join our Pilot Program</div>
            <h4
              style={{
                color: COLORS.white,
                fontWeight: 500,
                marginRight: 25,
                marginLeft: 25,
              }}
            >
              As our early access member, you'll be among the first to witness
              the features our product has to offer
            </h4>
            <button className="button" onClick={onClickAlphaInvite}>
              Join us today
            </button>
          </div>
        </FadeInOnScroll>
      </section>

      <section style={{ marginTop: "10vh" }}>
        <FAQ />
      </section>

      <InfiniteLooper speed="4" direction="left">
        <div className="infinite-loop-container">
          <h2 style={{ color: COLORS.primary }}>Coming Soon</h2>
          <h2 style={{ color: "black" }}>Coming Soon</h2>
        </div>
      </InfiniteLooper>

      <Footer />
    </div>
  );
}

export default App;
