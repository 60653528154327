import React from "react";
import "./BubbleCards.css";

const BubbleCards = () => {
  const data = [
    {
      user: "Hosts",
      content:
        "Have a free space in your house but don't wish to have a stranger living with you 24/7? Being a Rentdeer host is ideal for you!",
      subcontent:
        "Earn passive income by renting out your free space by the hour. Start listing your space on our marketplace today!",
      uri: require("../../assets/misc/hosts.jpg"),
    },
    {
      user: "Renters",
      content:
        "Want to chill with your friends but don't wish to pay for a cup of coffee to do so? Rentdeer is the solution for you!",
      subcontent:
        "At an affordable price, choose from multiple space options available for a fun day out!",
      uri: require("../../assets/misc/renters.jpg"),
    },
  ];

  const BubbleCard = ({ item }) => {
    return (
      <div className="bubble-card-container">
        <h1 className="main-title">
          For <span style={{ color: "white" }}>{item.user}</span>
        </h1>
        <div className="bubble-container">
          <div className="bubble-content left-header">
            <div style={{ fontSize: 20, paddingTop: 20 }}>{item.content}</div>
            <div style={{ fontSize: 20, paddingTop: 20 }}>
              {item.subcontent}
            </div>
          </div>
          <img className="bubble-img" src={item.uri} alt={item.user} />
        </div>
      </div>
    );
  };

  return (
    <div className="cards">
      {data.map((value, key) => {
        return <BubbleCard key={key} item={value} />;
      })}
    </div>
  );
};

export default BubbleCards;
