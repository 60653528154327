import React from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import "./ComingSoonCard.css";
import { COLORS } from "../../constants/Constants";

const ComingSoonCard = () => {
  const pointers = ["100% protection guarantee", "Zero upfront cost"];

  const Pointer = ({ text }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <IoCheckmarkCircle color="green" size={24} style={{ marginRight: 5 }} />
        <div style={{ fontSize: 18 }}>{text}</div>
      </div>
    );
  };

  return (
    <div className="coming-soon-container">
      <div className="component-header">
        Stay protected with {}
        <span style={{ color: COLORS.primary }}>Rentdeer Insurance</span>
      </div>
      <div className="coming-soon-content left-header">
        <div style={{ fontSize: 20, paddingBottom: 20 }}>
          Every space on our marketplace is insured for its content during
          rental hours. If your space gets damaged, Rentdeer will insure the
          damages.
        </div>
        {pointers.map((value, key) => {
          return <Pointer key={key} text={value} />;
        })}
        {/* <h2>Rent your space today with an ease of mind!</h2> */}
      </div>
      <h5 className="coming-soon-banner">Coming Soon</h5>
    </div>
  );
};

export default ComingSoonCard;
