import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FadeInOnScroll = ({ children, ...props }) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <motion.div
      ref={inViewRef}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      transition={{ duration: 0.8 }}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default FadeInOnScroll;
