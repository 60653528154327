import React from "react";
import logo from "../../assets/logo/rentdeer-black.png";
import instagram from "../../assets/socials/instagram.png";
import linkedin from "../../assets/socials/linkedin.png";
import { IoMailOutline } from "react-icons/io5";

import { CONSTANTS } from "../../constants/Constants";
import { onContactEmail } from "../../utils";
import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const SocialIcons = () => {
    return (
      <div className="social-icons">
        <a
          href="https://www.instagram.com/rentdeersg/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="app-icon" src={instagram} alt="Instagram" />
        </a>
        <a
          href="https://www.linkedin.com/company/rentdeer/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="app-icon" src={linkedin} alt="LinkedIn" />
        </a>
      </div>
    );
  };

  return (
    <footer>
      <div className="footer-container">
        <div className="footer-column">
          <img src={logo} className="footer-logo" alt="Rentdeer footer" />
          <h4 className="about-text">
            Discover the future of sharing: revolutionizing the way we approach
            renting. We aspire to be your one-stop platform for all your renting
            needs.
          </h4>
        </div>
        <div className="footer-column">
          <div
            className="mailto-icon"
            // onClick={() => {
            //   window.location.href = `mailto:${SUPPORT_EMAIL}`;
            // }}
            onClick={onContactEmail}
          >
            <IoMailOutline size={20} style={{ paddingRight: 8 }} />
            <p>{CONSTANTS.SUPPORT_EMAIL}</p>
          </div>
          <SocialIcons />
        </div>
        {/* <div className="footer-column">
          <p>Quick Links</p>
          <ul>
              <li>Contact Us</li>
              <li>Terms and Conditions</li>
              <li>Privacy Policy</li>
          </ul>
      </div> */}
      </div>
      <div className="line" />
      <p className="copyright">
        © {currentYear} Rentdeer. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
