import React from "react";
import Accordion from "../Accordion/Accordion";
import FadeInOnScroll from "../FadeInOnScroll";
import { COLORS } from "../../constants/Constants";

const FAQ = () => {
  const FAQS = [
    {
      question: "How does Rentdeer work?",
      answer:
        "Rentdeer connects property owners with users seeking short-term rental for unique spaces. Owners list their spaces, and renters can browse, book, and pay through the platform.",
    },
    {
      question: "Is Rentdeer available in my city/region?",
      answer:
        "Rentdeer currently operates in Singapore and aims to expand to major cities across Southeast Asia. Bookmark our website for the latest updates!",
    },
    {
      question: "How do I list my space on Rentdeer?",
      answer:
        "Owners can create a listing by providing details, photos, and availability. Our platform guides you through the process. Join our pilot program to gain early access to our mobile app.",
    },
    {
      question: "Is it safe to use Rentdeer?",
      answer:
        "Yes, Rentdeer prioritizes safety. We verify users, offer secure payment methods, and provide customer support for a hassle-free experience.",
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <FadeInOnScroll>
        <h1 style={{ color: COLORS.black }} className="main-title">
          FAQs
        </h1>
        <div className="accordion">
          {FAQS.map((item, key) => (
            <Accordion title={item.question} content={item.answer} />
          ))}
        </div>
      </FadeInOnScroll>
    </div>
  );
};

export default FAQ;
